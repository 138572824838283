@media screen and (max-width: 719px) {

    body {
        font-size: 18px;
    }

    .header, .content {
        padding: 0 24px;
    }

    .header .menu {
        display: none;
    }

    .header .burger {
        display: block;
    }

    .promo {
        height: auto;
        min-height: 0;
        padding: 128px 64px 176px;
        box-sizing: border-box;
    }

    .promo-wrapper {
        width: 100%;
    }

    .promo-title {
        font-size: 48px;
    }

    .promo-subtitle {
        margin: 16px auto 0;
        font-size: 18px;
    }

    .promo-buttons.landing {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 29px;
    }

    .promo-button.landing {
        flex: 1;
        font-size: 18px;
        line-height: 24px;
        padding: 12px 24px;
        margin: 16px 0 0 0;
        width: 200px;
        box-sizing: border-box;
        display: block;
    }

    .article .title {
        font-size: 40px;
    }

    .article .text,
    .article .list li {
        margin-top: 16px;
    }

    .article .text.last,
    .article .list {
        margin-bottom: 32px;
    }

    .article .list.sub {
        margin-left: 32px;
    }

    /* Cards */
    .card, .card-content {
        width: 120px;
        height: 180px;
    }

    .card_name {
        font-size: 10px;
        line-height: 16px;
    }

    .card:nth-child(1) {
        left: 40%;
        top: -148px;
        transform: scale(1.2);
    }

    .card:nth-child(2) {
        left: -72px;
        top: 50%;
    }

    .card:nth-child(3) {
        right: 64px;
        bottom: -48px;
        transform: scale(0.6);
    }

    .card:nth-child(4) {
        right: -72px;
        top: 15%;
        z-index: 2;
    }

    .card:nth-child(5) {
        right: -84px;
        top: 30%;
        transform: scale(0.9);
    }

    .faq-wrapper {
        padding: 0 24px;
        margin-top: 128px;
    }

    .faq-item {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 24px;
        margin-top: 48px;
    }

    .faq-question {
        font-size: 28px;
    }

    .faq-answer {
        font-size: 18px;
    }

    .faq-question-emoji, .faq-question-emoji svg {
        width: 28px;
        height: 28px;
    }

    .call-to-action-wrapper {
        height: auto;
        margin-top: 128px;
        padding: 128px 24px;
        box-sizing: border-box;
    }

    .call-to-action-title {
        font-size: 32px;
    }

    .call-to-action-wrapper .promo-buttons.landing {
        margin-top: 48px;
        width: 397px;
    }

    .call-to-action-wrapper .promo-button.landing:first-child {
        margin-top: 0;
    }

    .footer-wrapper {
        margin-top: 48px;
        flex-direction: column;
        padding: 0 24px;
        box-sizing: border-box;
    }

    .footer-links {
        margin-top: 48px;
    }


    .use-steps-wrapper {
        padding: 0 24px;
        box-sizing: border-box;
    }

    .use-step, .use-step.reverse {
        flex-direction: column;
        margin-top: 128px;
    }

    .use-step-content {
        flex: 1;
        margin:  0 !important;
    }

    .use-step-number {
        width: 48px;
        height: 48px;
    }

    .use-step-number svg {
        transform: scale(0.85);
    }

    .use-step-title {
        font-size: 32px;
        margin-top: 24px;
    }

    .use-step-body {
        font-size: 18px;
        margin-top: 8px;
    }

    .use-step-image-wrapper {
        flex: 1;
        height: initial;
        margin-top: 48px;
        width: 100%;
    }

    .use-step-image-wrapper:after {
        content: '';
        padding-top: 203%;
        width: 100%;
        display: block;
    }

    .use-step-image {
        width: 118%;
        height: 100%;
        left: -18.6% !important;
    }

    .use-step-image.step3 {
        left: 0 !important;
    }

    .use-step-image.step4 {
        left: -15.1% !important;
        width: 115.1%;
    }
}