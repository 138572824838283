@media screen and (min-width: 1200px) {

    .burger {
        display: none;
    }

    .header, .content {
        padding: 0 64px;
    }

    .content {
        width: calc(100vw - 128px);
    }

    .promo-title {
        font-size: 96px;
        line-height: 104px;
    }

    .promo-subtitle {
        margin: 32px auto 0;
        font-size: 20px;
        line-height: 160%;
    }

    .promo-buttons.landing {
        margin: 48px 0 0;
    }

    .use-step-content {
        width: 537px;
    }

    .card:nth-child(1) {
        left: 40%;
        top: -320px;
        transform: scale(1.2);
    }

    .card:nth-child(2) {
        left: -24px;
        top: 35%;
    }

    .card:nth-child(3) {
        left: 25%;
        bottom: -128px;
        transform: scale(0.6);
    }

    .card:nth-child(4) {
        right: -24px;
        top: 20%;
        z-index: 2;
    }

    .card:nth-child(5) {
        right: 64px;
        top: 50%;
        transform: scale(0.9);
    }
}

