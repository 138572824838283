@media screen and (min-width: 1024px) {
  body {
    font-size: 20px;
  }

  .promo-buttons {
    display: inline-block;
  }
}
.page.sharing .content {
  box-sizing: border-box;
  display: block;
  margin: 0 auto 110px;
  min-height: calc(100vh - 190px);
  padding: 24px 24px 0;
}
@media screen and (max-width: 640px) {
  .page.sharing .content {
    margin-bottom: 0;
    min-height: calc(100vh - 80px);
    padding: 0 20px;
    width: 100%;
  }
}
.page.sharing .share {
  height: calc(100vh - 214px);
}
@media screen and (max-width: 640px) {
  .page.sharing .share {
    height: calc(100vh - 80px);
  }
}
.page.sharing .share-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  height: 100%;
}
@media screen and (min-width: 640px) {
  .page.sharing .share-wrapper > * {
    flex: 1;
  }
}
.page.sharing .share-wrapper.mobile {
  display: none;
}
@media screen and (max-width: 640px) {
  .page.sharing .share-wrapper {
    display: none;
  }
  .page.sharing .share-wrapper.mobile {
    display: block;
  }
}
.page.sharing .share-wrapper.mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}
@media screen and (min-width: 640px) {
  .page.sharing .share-wrapper.mobile {
    display: none;
  }
}
.page.sharing .share-wrapper.mobile .share-image {
  text-align: center;
  width: 100%;
  height: calc(100vh - 206px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page.sharing .share-wrapper.mobile .share-image img {
  width: auto;
  border-radius: 16px;
}
.page.sharing .share-wrapper.mobile .share-title {
  text-align: left;
  position: absolute;
  bottom: 20px;
  font-size: 26px;
  line-height: 123%;
  color: #ffffff;
}
.page.sharing .share-wrapper.mobile .promo-button-wrap {
  width: 100%;
  display: block;
  border-radius: 100px;
  text-align: center;
}
.page.sharing .share-wrapper.mobile .open-in-app {
  padding: 12px 0;
  margin: 20px auto 16px;
  background: none;
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 133%;
}
.page.sharing .share-wrapper.mobile .share-info__block {
  text-align: center;
}
.page.sharing .share-wrapper.mobile .text {
  margin: 0 0 16px;
  text-align: center;
  color: #71737A;
  font-size: 16px;
  line-height: 125%;
}
.page.sharing .share .share-image {
  text-align: right;
}
@media screen and (min-width: 640px) {
  .page.sharing .share .share-image {
    max-width: 49vw;
  }
}
.page.sharing .share .share-image img {
  border-radius: 24px;
  visibility: hidden;
}
@media screen and (min-width: 640px) {
  .page.sharing .share .share-image img {
    float: right;
  }
}
.page.sharing .share .share-info {
  margin-left: 48px;
  margin-top: 100px;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .page.sharing .share .share-info {
    margin-top: 75px;
  }
}
@media screen and (max-width: 660px) {
  .page.sharing .share .share-info {
    margin-top: 15px;
  }
}
.page.sharing .share .share-info__block {
  margin: 0;
  max-width: 380px;
}
@media screen and (max-width: 1024px) {
  .page.sharing .share .share-info__block {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 640px) {
  .page.sharing .share .share-info__block {
    display: none;
  }
}
.page.sharing .share .share-info__block.mobile {
  display: none;
}
@media screen and (max-width: 640px) {
  .page.sharing .share .share-info__block.mobile {
    display: block;
  }
}
.page.sharing .share .share-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 140%;
  margin-bottom: 48px;
}
@media screen and (max-width: 1024px) {
  .page.sharing .share .share-title {
    font-size: 30px;
  }
}
.page.sharing .share .promo-buttons {
  margin-top: 24px;
}
.page.sharing .share .promo-buttons.app-links {
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .page.sharing .share .promo-buttons.app-links {
    display: none;
  }
}
.page.sharing .share .promo-button {
  margin: 0 16px 0 0;
  padding: 6px 24px;
}
@media screen and (max-width: 1024px) {
  .page.sharing .share .promo-button {
    margin-top: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .page.sharing .share .promo-button {
    float: left;
  }
}
.page.sharing .share .promo-button-icon {
  margin: 0 8px -5.5px -3px;
  width: 24px;
  height: 24px;
}
.page.sharing .share .open-in-app {
  white-space: nowrap;
}

.landing-preload {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  background-color: #ffffff;
}
.landing-preload.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}

.card:nth-of-type(3) {
  z-index: -2;
}

.card-content {
  opacity: 0;
  background-size: cover;
}
@keyframes cardSlideIn {
  0% {
    top: 20px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
.card-content.slide-in {
  animation: 0.3s cubic-bezier(0.26, 1.19, 1, 1) cardSlideIn;
  animation-fill-mode: forwards;
}
.card-content.slide-in-1 {
  animation-delay: 0s;
}
.card-content.slide-in-2 {
  animation-delay: 0.08s;
}
.card-content.slide-in-3 {
  animation-delay: 0.144s;
}
.card-content.slide-in-4 {
  animation-delay: 0.2s;
}
.card-content.slide-in-5 {
  animation-delay: 0.264s;
}

.nowrap {
  white-space: nowrap;
}

.light .promo-button.colored,
.light .promo-button-wrap {
  background: linear-gradient(166.02deg, #4830BF 0%, #A455F2 100%);
  color: #fff;
}

@media screen and (max-width: 680px) {
  .header .menu .item {
    padding-left: 13px;
    padding-right: 13px;
  }
}