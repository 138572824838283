/* Basic */

html {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    -webkit-text-size-adjust: none;
}

body {
    position: relative;
    margin: 0;
    font-family: 'Nunito', sans-serif;
    line-height: 160%;
}

h1, h2, h3 {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}



/* General */

.dark {
    background: #0B0D14;
    color: #fff;
}

.light {
    background: #fff;
    color: #0B0D14;
}

.page {
    min-height: 100vh;
    overflow: hidden;
}

.header {
    overflow: hidden;
    z-index: 3;
    position: relative;
}

.header .logo {
    float: left;
    margin: 16px 0 16px -16px;
    padding: 8px 16px;
    font-weight: 800;
    line-height: 32px;
}

.header .logo .glyph {
    margin: -8px 8px -8px 0;
}

.header .menu {
    float: right;
    margin: 0 -16px 0 0;
}

.header .menu .item {
    float: left;
    display: block;
    height: 32px;
    padding: 8px 16px;
    margin: 16px 0;
}

.header .menu .item::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    margin: 8px auto 0;
    border-radius: 100px;
    opacity: 0.84;
    transition: width .15s;
}

.header .menu .item:hover::after,
.header .menu .item.selected::after {
    width: 24px;
}

.header .burger {
    float: right;
    width: 64px;
    height: 64px;
    margin: 8px -16px 8px 8px;
    position: relative;
    cursor: pointer;
}

.header .burger::before,
.header .burger::after {
    content: '';
    width: 24px;
    height: 2px;
    position: absolute;
    left: 20px;
    z-index: 1;
    border-radius: 100px;
    transition: all .15s;
}

.header .burger::before {top: 26px}

.header .burger::after {top: 36px}

.header .burger:hover::before {left: 24px}

.header .burger:hover::after {left: 16px}

.dark .header .menu .item::after,
.dark .header .burger::before,
.dark .header .burger::after {
    background: #fff;
}

.light .header .menu .item::after,
.light .header .burger::before,
.light .header .burger::after {
    background: #0B0D14;
}

.content {
    display: table;
    min-height: calc(100vh - 320px);
    margin: 80px 0 160px;
}


.bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
}

.bg .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.64;
}


.nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    transition: all .15s ease-in-out;
}

.nav.hidden {
    display: none;
}

.nav.visible {
    display: block;
}

.nav.start {
    background: rgba(0, 0, 0, 0);
}

.nav.end {
    background: rgba(0, 0, 0, .32);
}

.nav .menu {
    position: absolute;
    top: 16px;
    right: 16px;
    background: #fff;
    color: #0B0D14;
    border-radius: 12px;
    padding: 8px 0;
    transition: all .15s ease-in-out;
    overflow: hidden;
}

.nav.start .menu {
    width: 0;
    height: 0;
}

.nav.end .menu {
    width: 160px;
    height: 192px;
}

.nav .menu .item {
    display: block;
    padding: 12px 16px;
    line-height: 24px;
}

.nav .menu .item:hover {
    background: rgba(206, 208, 214, 0.24);
}



/* Promo */

.promo {
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 1;
    height: 100vh;
    min-height: 800px;
    display: flex;
    align-items: center;
    margin-top: -80px;
}

.promo-wrapper {
    max-width: 720px;
    margin: 0 auto;
}

.promo-title {
    font-weight: 800;
    line-height: 120%;
}

.promo-subtitle {
    max-width: 480px;
    font-weight: 400;
    line-height: 160%;
}

.promo-buttons {
    display: inline-block;
}

.promo-button {
    display: block;
    max-width: 270px;
    font-weight: 800;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 100px;
    transition: background 0.15s;
    margin: 0 12px;
    padding: 12px 32px;
}
.promo-button.landing {
    float: left;
}

.promo-button:hover {
    background: rgba(255, 255, 255, 0.32);
}

.promo-button.filled {
    background: linear-gradient(163.81deg, #4830BF 0%, #A455F2 100%);
    color: #fff;
    transition: opacity 0.15s;
}

.promo-button.filled:hover {
    opacity: 0.9;
}

.promo-button-icon {
    margin: 0 8px -10px 0;
}

/* .promo-button-ios {
  opacity: 0.48;
  pointer-events: none;
} */



/* Article */

.article {
    max-width: 1024px;
}

.article .title {
    line-height: 120%;
}

.article .list {
    padding: 0;
    list-style: none;
    font-weight: 400;
}

.article .list.primary {
    font-weight: 800;
    margin: 0;
}

.article .list.numeric {
    counter-reset: li;
}

.article .list.numeric>li:before {
    counter-increment: li;
    content: counters(li,'.') '. ';
}

.article .link {
    text-decoration: underline;
}

.article .link:hover {
    text-decoration: none;
}

/* Cards */

.cards-wrapper {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 100%;
}

.card {
    display: flex;
    align-items: flex-end;
    position: absolute;
    border-radius: 24px;
    background-position: center;
    box-sizing: border-box;
    width: 240px;
    height: 360px;
    z-index: 1;
}
.card-content {
    padding: 12px 16px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    box-shadow: 0 16px 48px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
    background-position: center;
    box-sizing: border-box;
    width: 240px;
    height: 360px;
    overflow: hidden;
}

.card_name {
    position: relative;
    z-index: 2;
    font-weight: 800;
    font-size: 20px;
    line-height: 32px;
    color: #fff;
}

.card-content:after {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.32) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* Use Steps */
.use-steps-wrapper {
    width: 100%;
}

.use-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 128px;
}

.use-step.reverse {
    flex-direction: row-reverse;
}

.use-step-content {
    color: #0B0D14;
    margin-right: 64px;
}

.use-step.reverse .use-step-content {
    margin-left: 64px;
    margin-right: 0;
}

.use-step-image-wrapper {
    flex: 0 0 auto;
    width: 423px;
    height: 860px;
    position: relative;
}

.use-step-image {
    background: no-repeat center/cover;
    width: 502px;
    height: 860px;
    position: absolute;
    top: 0;
}

.use-step-image.step1 {
    left: -79px;
}

.use-step-image.step2 {
    left: -79px;
}

.use-step-image.step3 {
    left: 0;
}

.use-step-image.step4 {
    left: -64px;
    width: 487px;
}

.use-step-number {
    width: 56px;
    height: 56px;
}

.use-step-title {
    font-weight: 800;
    font-size: 48px;
    line-height: 115%;
    margin-top: 32px;
}

.use-step-body {
    margin-top: 16px;
    font-size: 20px;
    line-height: 160%;
}

/* FAQ */

.faq-wrapper {
    max-width: 1024px;
    margin: 256px auto 0;
}

.faq-item {
    margin-top: 64px;
    margin-right: 128px;
    background: rgba(206, 208, 214, 0.24);
    border-radius: 24px;
    padding: 48px 64px;
    color: #0B0D14;
}

.faq-item.reverse {
    margin-left: 128px;
    margin-right: 0;
}

.faq-item:first-child {
    margin-top: 0;
}

.faq-question {
    display: inline-block;
    align-items: center;
    font-weight: 800;
    font-size: 36px;
    line-height: 130%;
}

.faq-question-emoji {
    width: 36px;
    height: 36px;
    margin-left: 8px;
    background: no-repeat center/contain;
    vertical-align: middle;
}

.faq-question-emoji.q1 {
    background-image: url('/images/q1.svg');
}

.faq-question-emoji.q2 {
    background-image: url('/images/q2.svg');
}

.faq-question-emoji.q3 {
    background-image: url('/images/q3.svg');
}

.faq-question-emoji.q4 {
    background-image: url('/images/q4.svg');
}

.faq-answer {
    margin-top: 8px;
    font-size: 20px;
    line-height: 160%;
}

/* Call to action */

.call-to-action-wrapper {
    margin-top: 256px;
    height: 671px;
    background: linear-gradient(156.96deg, #4830BF 0%, #A455F2 100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.call-to-action-title {
    color: #fff;
    font-weight: 800;
    font-size: 48px;
    line-height: 115%;
    text-align: center;
}

.call-to-action-wrapper .promo-button {
    color: #fff;
}

/* Footer */

.footer-wrapper {
    max-width: 1024px;
    margin: 64px auto 128px;
    display: flex;
}

.footer-logo {
    flex: 1;
    height: 32px;
}

.footer-links {
    flex: 0 0 auto;
    display: flex;
}

.footer-links-section {
    font-size: 20px;
    line-height: 160%;
    color: #0B0D14;
    width: 120px;
    margin-left: 16px;
}

.footer-links-section:first-child {
    margin-left: 0;
}

.footer-links-section-title, .footer-links-section-link {
    margin-top: 16px;
}

.footer-links-section-title {
    font-weight: 800;
}

.footer-links-section-link {
    display: block;
}

.footer-links-section-link:hover {
    text-decoration: underline;
}
